/* Mixins */
@mixin main {
    height: 100vh;
    background-color: $seGreen;
    display: flex;
    flex-direction: column;
}

@mixin steps {
    background-color: #f4f4f4;
    height: 100%;
    width: 100%;
    border-left: 0;
    border-right: 0;
    display: flex;
    flex-direction: column;
}

@mixin submit-btn {
    text-transform: none;
    transition: all 0.3s;
    background: linear-gradient(0deg, $seLightGreen, $seGreen 80%) no-repeat;
    color: #f4f4f4;
    font-size: 1.15rem;
    min-width: 360px;
    max-width: 360px;
    min-height: 80px;
    max-height: 80px;
    box-shadow: 0px 0px 2.5px #000;
    border: 1px solid darkgreen;
    font-weight: bold;
    justify-content: space-evenly;
    text-decoration: none;
    &:hover {
        box-shadow: 0px 0px 15px #000;
    }

    svg {
        width: 30px;
        height: 30px;
    }
    @media screen and (max-width: $mobileBreakpoint) {
        font-size: 1rem;
        min-width: 320px;
        max-width: 320px;
        min-height: 60px;
        max-height: 60px;
        svg {
            width: 25px;
            height: 25px;
        }
    }
}

@mixin modal {
    .modal {
        display: none;
        .modal-overlay {
            opacity: 0.75;
            background-color: dimgray;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .modal-content {
            opacity: 1;
            position: fixed;
            width: 75%;
            left: 50%;
            margin-left: -37.5%;
            height: 125px;
            background-color: #f4f4f4;
            border: 4px solid $seGreen;
        }
    }
}

@mixin form-content {
    align-self: center;
    border-radius: 6px;
    min-height: 50px;
    max-height: 50px;
    min-width: 50px;
    max-width: 50px;
    .MuiFormLabel-root {
        &.Mui-focused {
            color: #f4f4f4;
        }
    }
    .MuiOutlinedInput-root {
        &.Mui-focused {
            fieldset {
                border-color: #f4f4f4;
            }
        }
    }
    .loading-btn {
        height: 100%;
        min-height: 50px;
        max-height: 50px;
        min-width: 50px;
        max-width: 50px;
    }
    .search-btn {
        background-color: #fff;
        border-radius: 6px;
        min-height: 50px;
        max-height: 50px;
        min-width: 50px;
        max-width: 50px;
        height: 100%;
    }
}

@mixin manual-input-form {
    padding: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 6px;
    padding-bottom: 6px;
    max-width: 500px;
    min-width: 500px;
    @media screen and (max-width: $mobileBreakpoint) {
        min-width: unset;
    }
    &.inline {
        display: inline-flex;
    }
    .input-div {
        display: inline-flex;
        justify-content: center;
        .input-field {
            display: flex;
            margin-right: 10px;
            width: 100%;
            color: #fff;
            .Mui-focused {
                color: #fff;
                font-weight: bold;
            }
            fieldset {
                border-color: #fff;
            }
        }

        .MuiCircularProgress-root {
            text-align: center;
            color: #fff;
        }
        .form-content {
            @include form-content();
        }
    }
}

@mixin sign-in-up-form {
    animation: fadeIn 0.75s;
    .MuiFormLabel-root {
        &.Mui-focused {
            color: $seGreen;
        }
    }

    .MuiOutlinedInput-root {
        &.Mui-focused {
            fieldset {
                border-color: $seGreen;
            }
        }
    }
    .check {
        &.Mui-checked {
            color: $seGreen;
        }
    }
    #submit-btn {
        background-color: $seGreen;
        &:hover {
            color: dimgray;
            background-color: $seLightestGreen;
        }
    }
}

@mixin step-footer {
    flex: 10% 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .sku-availability-msg {
        margin: 0;
        font-weight: 300;
        .anchor-element {
            font-weight: bold;
            text-decoration: none;
            color: #fff;
        }
    }
    .help {
        border: 1px solid #363636;
        font-size: 0.85rem;
        font-weight: bold;
        padding: 5px 10px;
        background-color: $seLightGreen;
        text-decoration: none;
        color: #f4f4f4;
        border-radius: 3px;
        @media screen and (max-width: $mobileBreakpoint) {
            font-size: 0.7rem;
        }
    }
}

@mixin admin-panel {
    .card {
        .card-content {
            .card-actions {
                flex-direction: column;
                .manual-input-form {
                    @include manual-input-form();
                }
                .message {
                    @media screen and (max-width: $mobileBreakpoint) {
                        font-size: 1rem;
                        font-weight: bold;
                        color: #3f51b5;
                    }
                }
            }
        }
    }
}

@mixin admin-login-div {
    .login-btn,
    .admin-btn {
        color: #fff;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        min-width: 50px;
        max-width: 50px;
        .login-icon,
        .admin-icon {
            min-width: 100%;
            min-height: 100%;
            padding: 2px;
        }
        &.active {
            .login-icon,
            .admin-icon {
                animation: fadeIn 0.5s;
                border-radius: 5px;
                box-shadow: 0px 0px 10px #f4f4f4;
                background: #f4f4f4;
                color: $seGreen;
            }
        }
    }
}

@mixin search-input-title {
    text-align: center;
    margin-bottom: 5px;
    color: #f4f4f4;

    @media screen and (max-width: $mobileBreakpoint) {
        margin-left: 0px;
    }
}

@mixin copyright {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .nu-logo {
        background-color: #fff;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        height: 25px;
        padding: 2px 5px;

        @media screen and (max-width: $mobileBreakpoint) {
        }
    }
    .cpr-text {
        color: #fff;
    }
}

@mixin form-div {
    margin: auto;
    .signed-in {
        display: flex;
        flex-direction: column;
        button {
            color: $seLightestGreen;
            border-color: $seLightestGreen;
        }
        .admin-panel-link {
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            position: relative;
            box-sizing: border-box;
            -webkit-tap-highlight-color: transparent;
            background-color: transparent;
            outline: 0;
            border: 0;
            margin: 0;
            border-radius: 0;
            padding: 0;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            vertical-align: middle;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            -webkit-text-decoration: none;
            text-decoration: none;
            color: inherit;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.75;
            letter-spacing: 0.02857em;
            text-transform: uppercase;
            min-width: 64px;
            padding: 5px 15px;
            border-radius: 4px;
            -webkit-transition:
                background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition:
                background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            border: 1px solid rgba(25, 118, 210, 0.5);
            color: #1976d2;
            margin-bottom: 16px;
        }
    }
    #sign-in-form,
    #sign-up-form {
        @include sign-in-up-form();
    }
}

@mixin additional-msg-div {
    p {
        color: red;
        font-weight: 500;

        @media screen and (max-width: $mobileBreakpoint) {
            font-size: 14px;
        }
    }
}

@mixin go-back-div {
    button {
        svg {
            fill: #fff;
            width: 40px !important;
            height: 40px !important;
        }
    }
}

@mixin step-two-product-div {
    border: 0;
    padding: 5px 10px;
    margin: auto;
    background-color: #fff;
    min-height: 75%;
    max-height: 75%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    margin-right: 15px;
    @media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
        overflow-y: scroll;
        min-height: unset;
        max-height: 300px;
    }
    @media screen and (max-width: $mobileBreakpoint) {
        overflow-y: scroll;
        display: unset;
        max-width: 100%;
        min-height: unset;
        max-height: 200px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        margin: 0;
    }
    .content {
        display: flex;
        flex-direction: column;
        .product-img {
            min-width: 300px;
            min-height: 300px;
            max-width: 300px;
            max-height: 300px;
            margin: auto;
            border-radius: 5px;

            @media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
                min-width: 200px;
                min-height: 200px;
                max-width: 200px;
                max-height: 200px;
            }
            @media screen and (max-width: $mobileBreakpoint) {
                min-width: 120px;
                min-height: 120px;
                max-width: 120px;
                max-height: 120px;
            }
        }
        .product-desc-div {
            margin: auto;
            .product-desc {
                color: #000;
                text-align: justify;
            }
        }
    }
}

@mixin step-two-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-width: 100%;
    padding: 5px 10px;

    .section-success,
    .section-error {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        max-width: 100vw;
        max-height: 85vh;
        text-align: left;
        @media screen and (max-width: $mobileBreakpoint) {
            max-height: 100vh;
        }
        &.hide {
            display: none;
        }
        .main-prod-div {
            border: 2px solid white;
            border-radius: 15px;
            margin: 5px 15px;
            flex: 3;
            display: flex;
            flex-direction: row;

            @media screen and (max-width: $mobileBreakpoint) {
                justify-content: space-between;
                flex: 3;
                flex-direction: column;
                margin: 0px;
            }
            .prod-details {
                flex: 1;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                @media screen and (max-width: $mobileBreakpoint) {
                    padding: 0 10px;
                    margin: auto 0;
                }

                .cta-div {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    margin-bottom: 10px;
                }
            }
        }
        .custom-message {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            flex: 1;
            .instruction {
                position: relative;
                max-width: fit-content;
                margin: 0 auto;
                text-align: center;
                .error-msg {
                    margin: 0;
                    font-size: 0.75rem;
                    color: red;
                }
            }
        }
        h5 {
            text-align: center;
            @media screen and (max-width: $mobileBreakpoint) {
                font-size: 1.75rem;
            }
        }
        h5 {
            @media screen and (max-width: $mobileBreakpoint) {
                font-size: 1.25rem;
            }
        }
        h6 {
            @media screen and (max-width: $mobileBreakpoint) {
                font-size: 1rem;
            }
        }
        .product-div {
            flex: 2;
            border-radius: 5px;
            @include step-two-product-div();
        }
        .manual-input-form {
            @include manual-input-form();
        }
        .additional-msg-div {
            @include additional-msg-div();
        }
        .go-back-div {
            @include go-back-div();
        }
    }
    .section-error {
        justify-content: space-between;
        text-align: center;

        .MuiCircularProgress-root {
            color: #fff;
            width: 50px !important;
            height: 50px !important;
        }

        .found-product-div {
            flex: auto;
            display: flex;
            flex-direction: column;
            max-height: 500px;
            margin: 0 auto;
            @media screen and (max-width: 1600px) {
                max-height: 450px;
            }
            @media screen and (max-width: 1300px) {
                max-height: 350px;
            }
            @media screen and (max-width: $mobileBreakpoint) {
                max-height: 250px;
            }
            .list {
                width: 55vw;
                background-color: inherit;
                overflow-y: scroll;
                scrollbar-color: #3dcd58;
                padding: 0;
                @media screen and (max-width: $mobileBreakpoint) {
                    width: 85vw;
                }
                .found-product {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    background: #0000001c;
                    margin: auto;
                    padding: 15px;
                    border-bottom: 2px solid lightgray;
                    align-items: center;
                    @media screen and (max-width: $mobileBreakpoint) {
                        flex-direction: column;
                    }
                    .found-product-img {
                        max-height: 120px;
                        max-width: 120px;
                        min-height: 120px;
                        min-width: 120px;
                        border-radius: 100%;
                        @media screen and (max-width: $mobileBreakpoint) {
                            max-height: 100px;
                            max-width: 100px;
                            min-height: 100px;
                            min-width: 100px;
                        }
                    }
                    .found-product-title {
                        margin: 0 25px;
                        padding: 0 25px;
                        max-width: 300px;
                        @media screen and (max-width: $mobileBreakpoint) {
                            margin: 5px 0px;
                            padding: 0 5px;
                        }
                    }
                }
            }
        }
    }
}

@mixin step-two-card {
    border: 2px solid $seLightGreen;
    border-radius: 0;
    background-color: $seLightestGreen;
    box-shadow: none;
    display: flex;
    color: white;
    justify-content: center;
    flex: 1;
    min-width: 100%;
    &.error {
        min-width: 100%;
    }

    .card-content {
        @include step-two-card-content();
    }
}

@mixin step-two {
    .se-logo-div {
        margin-top: 15px;
        a {
            .se-logo {
                width: 150px;
                height: 60px;
                @media screen and (max-width: $mobileBreakpoint) {
                    width: 150px;
                    height: 60px;
                }
            }
        }
    }
    .card {
        @include step-two-card();
    }
}

@mixin step-one-two {
    animation: fadeIn 1s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    display: flex;
    flex: 1;
    &.hide {
        display: none;
    }
}

@mixin login-admin-panel {
    animation: fadeIn 0.75s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    .title-div {
        flex: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .card {
        flex: 1;
    }
}

@mixin step-one-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    min-height: 100%;
    justify-content: space-between;
    .desc-box {
        flex: 15%;
        display: flex;
        .desc {
            text-align: center;
            font-weight: bold;
            margin: auto;
            @media screen and (max-width: $mobileBreakpoint) {
                font-size: 1.2rem;
            }

            &.hide {
                display: none;
            }
        }
    }

    .card-actions {
        flex-direction: column;
        flex: 5;
        justify-content: space-evenly;
        width: 100%;
        @media screen and (max-width: $mobileBreakpoint) {
            flex-direction: column;
        }
        .manual-sku-box {
            animation: fadeIn 0.75s;
            min-height: 30%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .additional-msg-div {
                @include additional-msg-div();
            }
        }

        .found-product-div {
            margin: 0 auto;
            .found-product {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                background: #0000001c;
                margin: auto;
                padding: 15px;
                border: 2px dashed #707070;
                align-items: center;
                .found-product-img {
                    max-height: 150px;
                    max-width: 150px;
                    min-height: 150px;
                    min-width: 150px;
                    border-radius: 100px;
                }
                .found-product-title {
                    margin: 0 25px;
                    max-width: 300px;
                    padding: 0 25px;
                }
            }
        }
        .upload-icon {
            width: 80px;
            height: 80px;
            margin: auto;
        }
        .hide {
            display: none;
        }
        .show {
            animation: fadeIn 0.75s;
        }
        .loading-btn {
            .MuiCircularProgress-root {
                color: #fff;
                width: 75px !important;
                height: 75px !important;
            }
        }
        .submit-btn {
            @include submit-btn();
        }
        .search-input-title {
            @include search-input-title();
        }
        .manual-input-form {
            @include manual-input-form();
        }
        .go-back-div {
            @include go-back-div();
            button {
                position: relative;
                min-width: 50px;
                max-width: 50px;
                min-height: 50px;
                max-height: 50px;
                svg {
                    min-width: 50px !important;
                    min-height: 50px !important;
                    max-width: 50px !important;
                    max-height: 50px !important;
                }
            }
        }
    }
    .step-footer {
        @include step-footer();
    }
}

@mixin step-one-card {
    flex: 4;
    min-width: 100%;
    background-color: $seLightestGreen;
    border-top: 2px solid $seLightGreen;
    border-bottom: 2px solid $seLightGreen;
    box-shadow: none;
    border-radius: 0px;
    display: flex;
    color: white;
    justify-content: center;

    .loading-btn {
        .MuiCircularProgress-root {
            color: #fff;
            width: 75px !important;
            height: 75px !important;
        }
    }
    .card-content {
        @include step-one-card-content();
    }
}

@mixin step-one {
    .se-logo-div {
        margin-top: 15px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            .se-logo {
                width: 150px;
                height: 60px;
            }
        }
    }
    .title-div {
        flex: 0;
        margin: 15px 20px;
        @media screen and (max-width: $mobileBreakpoint) {
            margin: 5px 30px;
        }
        h5 {
            @media screen and (max-width: $mobileBreakpoint) {
                font-size: 1.5rem;
            }
        }
        h6 {
            @media screen and (max-width: $mobileBreakpoint) {
                font-size: 1.2rem;
            }
        }
    }

    .card {
        @include step-one-card();
    }
}
