/* Variables */
$seGreen: #689f38;
$seLightGreen: #259d4e;
$seLightestGreen: #3dcd58;
$mobileBreakpoint: 991px;

/* Imports */
@import "./mixin.scss";
@import "./keyframes.scss";

::-webkit-scrollbar {
  background-color: $seLightestGreen;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}

/* Custom styles */
main {
  @include main();

  .nav-component {
    .se-logo-div {
      a {
        .se-logo {
          width: 150px;
          height: 40px;
          @media screen and (max-width: $mobileBreakpoint) {
            height: 30px;
          }
        }
      }
    }
    .login-btn-div,
    .admin-btn-div {
      @include admin-login-div();
    }
  }
  .steps {
    @include steps();

    .se-logo-div {
      a {
        .se-logo {
          width: 150px;
          height: 60px;
        }
      }
    }
    .step-one {
      @include step-one();
    }

    .step-two {
      @include step-two();
    }

    .step-one,
    .step-two {
      @include step-one-two();
    }

    .panel {
      @include steps();
      .login-panel,
      .admin-panel {
        @include step-one();
        @include login-admin-panel();
      }
      .login-panel {
        justify-content: flex-start;
        padding: 5px 15px;
        .form-div {
          @include form-div();
        }
      }

      .admin-panel {
        @include admin-panel();
      }
    }
  }
  .copyright {
    @include copyright();
  }
}
